.board_container {
  width: 100%;
  overflow: scroll;
}

.board_container_child {
  display: flex;
  gap: 30px;
  width: 100%;
  height: 85vh;
}

.milestone_due {
  font-size: 14px;
  margin-top: 6px;
  color: #929292;
}
