.milestone_title {
  font-size: 24px;
  font-weight: 500;
  color: #333333;
}

.milestone_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.milestone_due {
  font-size: 14px;
  margin-top: 6px;
  color: #929292;
}

.task_modal_content {
  padding: 20px;
}

.task_modal_flex {
  display: flex;
  align-items: center;
}

/* .ant-modal-content {
  border-radius: 20px 20px 20px !important;

} */
